
<template lang="pug">
.wrapper.i-wrap
  .section-left
  .section-mid
    div(v-if="isLoading")
      Spinner
    div(v-else)
      .search-wrap(v-if="results.length > 0")
        h3.sectionTitle Search: {{searchQuery}}
        .items
          .item(v-for="result in results")
            SearchResult(
              :type="type"
              :result='result'
            )
      .pagination-wrap(v-if="maxPage > 1")
        .min-max-page(v-if="page != 1 && page -1 != 1")
          button(@click="prevPage(1)") 1
          p ...
        button.prev-page(v-if="page > 1" @click="prevPage(page-1)") {{page - 1}}
        button.current-page {{page }}
        button.next-page(v-if="page < (maxPage)" @click="nextPage(page+1)") {{page + 1}}
        .min-max-page(v-if="page != maxPage && page +1 != maxPage")
          p ...
          button(@click="nextPage(maxPage)") {{maxPage}}
  .section-right

</template>

<script>
import SearchResult from '@/components/SearchResult';
import Spinner from 'vue-simple-spinner';
export default {
  name: 'AdvancedSearch',
  components:{
    SearchResult,
    Spinner
  },  
  metaInfo() {
    var x = this.isLoading;
    var theName = '';
    // if (!x){
      theName = this.searchQuery
    // }
    return {
      title: this.isLoading ? 'Loading...': theName,
      titleTemplate: "%s - 臭豆腐 - 電影∣影集∣評分∣評論"
    }
  },
  data(){
    return{
      isLoading:true,
      searchQuery:'',
      results:[],
      page:1,    
      type:'',  
      maxPage:'',
    }
  },

  methods:{
    async search(){
      this.isLoading = true;
      var results = await this.axios(`/api/advancedSearch?query=${this.searchQuery}&type=${this.type}&page=${this.page}`)
      this.results = results.data.results
      this.count = results.data.count
      this.maxPage = Math.ceil(results.data.count / 30)
      this.isLoading = false;
    },
    async prevPage(page){
      this.page = page;
      this.search()
      history.pushState(
        {},
        null,
        `/advancedSearch?query=${this.searchQuery}&type=${this.type}&page=${this.page}`
      )

    },
    async nextPage(page){
      this.page = page;
      this.search()
      history.pushState(
        {},
        null,
        `/advancedSearch?query=${this.searchQuery}&type=${this.type}&page=${this.page}`
      )
    }
  },
  async created(){
    this.searchQuery = this.$route.query.query;
    this.type = this.$route.query.type;
    this.page = Number(this.$route.query.page);
    this.search()
  },
  
}
</script>
<style lang="scss" scoped>
  .pagination-wrap{
    display:flex;
    justify-content: center;
    button{
      margin:0 5px;
      border:solid 1px rgb(118, 118, 118);
      border-radius:3px;
    }
    .current-page{
      background-color:white;
    }
  }
  .search-wrap{
    display:flex;
    flex-direction: column;
     
  }
  h3{
    display:inline-block;
    margin:0 auto;
    // text-align:center;
    // border
  }
  .min-max-page{
    display:flex;
  }
</style>
