<template lang="pug">
div
  router-link( v-bind:to="`/${result.model}/${result._id}`")
    .search-result-wrap
      .searchPoster
        img(v-bind:src="`${result.thumbnail}`")
      span.title(v-if="result.nameEN && result.nameCH")
        span.titleOne(v-html="result.nameCH")
        br
        span.titleTwo(v-html="result.nameEN")
      span.title(v-else)
        span.titleOne(v-html="result.nameEN")
</template>

<script>

export default {  
  name: 'SearchResult',
  components:{
  },
  props:{
    result: Object
  },
  data(){
    return{      
      
    }
  },
}
</script>
<style lang="scss" scoped>
.search-result-wrap{
  width:100%;
  display:flex;
  align-items: center;
  margin:5px 0;
  transition:background-color .3s;
  &:hover{
    background-color:lightgrey;
  }
  img{
    width:30px;
    margin-right:20px;
  }
}
</style>
